import React from 'react'

import Layout from '../components/layouts/Layout'
import ErrorMessage from '../components/common/ErrorMessage'

export default function NotFound() {
  return (
    <Layout>
      <ErrorMessage title="404" message="This page does not exist." />
    </Layout>
  )
}
